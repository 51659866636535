@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'SF UI Semibold';
  src: url(./fonts/SFUIText-Semibold.ttf);
}
@font-face {
  font-family: 'SF UI Regular';
  src: url(./fonts/SFUIText-Regular.ttf);
}
@font-face {
  font-family: 'SF UI Medium';
  src: url(./fonts/SFUIText-Medium.ttf);
}
@font-face {
  font-family: 'SF UI Heavy';
  src: url(./fonts/SFUIText-Heavy.ttf);
}
@font-face {
  font-family: 'SF UI Light';
  src: url(./fonts/SFUIText-Light.ttf);
}

@font-face {
  font-family: 'SF UI Bold';
  src: url(./fonts/SFUIText-Bold.ttf);
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
