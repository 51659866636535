body {
  /* margin: auto;
  max-width: 768px; */
  background-color: #0a0a0d;
}

.container {
  max-width: 700px;
  margin: auto;
}

body {
  animation: fadeInAnimation ease 2s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

::-moz-selection {
  /* Code for Firefox */
  color: #1b2305;
  background: #bede64;
}

::selection {
  color: #1b2305;
  background: #bede64;
}

.divider {
  width: 100%;
  border: 1.5px solid #c4c4c4;
  margin-left: 5em;
  margin-right: 5em;
}
